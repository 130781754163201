<template>
  <td class="p-2" :class="setStyle">{{ index + 1 }}</td>
  <!-- 출고상태 -->
  <td class="p-2" :class="setStyle">
    <ArticleStatusUI
      :approve-code="cueArticle.apprvTypCd"
      :is-cuesheet-matching="cueArticle?.cueMatchCnt > 0"
    />
  </td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <!-- 형식 -->
  <td class="p-2" :class="setStyle">
    <ArticleTypeUI :item="cueArticle.artclTypCd" />
  </td>

  <!-- 제목 -->
  <td class="p-2" :class="setStyle">
    <div class="flex items-center space-x-1.5">
      <longArrowDownRightIcon
        v-if="setIsCopyArticle"
        class="w-5 h-5 text-red-600"
      />
      <div class="w-56 truncate">
        {{ cueArticle.artclTitl }}
      </div>
    </div>
  </td>

  <!-- 기자명 -->
  <td class="p-2" :class="setStyle" :title="setReporterName">
    <div class="truncate">
      {{ setReporterName }}
    </div>
  </td>

  <!-- 뉴스 -->
  <td class="p-2" :class="setStyle">{{ cueArticle.brdcPgmNm }}</td>

  <!-- 작성일시 -->
  <td class="p-2" :class="setStyle">{{ setTransferDtm }}</td>

  <!-- 길이 -->
  <td class="p-2" :class="setStyle">{{ setArticleTime }}</td>
</template>

<script>
import { defineComponent, computed } from 'vue';
import { ArticleStatusUI } from '@/components/article/commonUI';
import { ArticleTypeUI } from '@/components/article/commonUI';
import dayjs from 'dayjs';
// import { GIconButton } from '@/components/ui';
import { secondToMs } from '@/utils/format.ts';
import { articleDivisionCode /*, articleApproveCode*/ } from '@/codes';
import { displayDateTimeHm } from '@/utils/format';
import { longArrowDownRightIcon } from '@/components/ui/icon';

export default defineComponent({
  name: 'CueArticleSearchListItem',
  components: {
    ArticleStatusUI,
    ArticleTypeUI,
    // GIconButton,
    longArrowDownRightIcon,
  },
  props: {
    cueArticle: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    /**
     * 기사 시간 : 앵커멘트 + 리포트
     */
    const setArticleTime = computed(() => {
      let seconds =
        props.cueArticle.artclCttTime +
        props.cueArticle.ancMentCttTime +
        props.cueArticle.artclExtTime;

      return secondToMs(seconds);
    });

    // 송고 일시(작성 일시)
    const setTransferDtm = computed(() => {
      return displayDateTimeHm(props.cueArticle?.inputDtm);
    });

    // 출고 일시
    // const setApproveDtm = computed(() => {
    //   if (props.cueArticle.apprvTypCd === articleApproveCode.noneApprove.id) {
    //     return '';
    //   }
    //   return displayDateTimeHm(props.cueArticle?.apprvTypDtm);
    // });

    /**
     * div_001: 일반
     * div_002: 예정
     * div_003: 엠바고
     */
    const setStyle = computed(() => {
      if (props.cueArticle.artclDivCd === articleDivisionCode.expected.id) {
        return 'text-cyan-500';
      } else if (
        props.cueArticle.artclDivCd === articleDivisionCode.embargo.id &&
        dayjs().isBefore(dayjs(props.cueArticle.artclDivDtm).format())
      ) {
        return 'text-red-500';
      }

      return '';
    });

    const setReporterName = computed(() => {
      return props.cueArticle.isSameChannel
        ? props.cueArticle.rptrNm
        : `${props.cueArticle.orgRptrNm}(${props.cueArticle.orgChNm})`;
    });

    /**
     * 복사된 기사 표시 여부
     * artclOrd는 작성자와 방송 예정일이 원본 기사와 동일한 경우에 증가한다.
     * artclOrd가 0보다 큰 경우엔 원본기사 하위에 등록되고
     * 0 인경우에는 새 기사로 인식하여 최상단에 등록된다.
     */
    const setIsCopyArticle = computed(() => {
      return props.cueArticle.orgArtclId && props.cueArticle.artclOrd;
    });

    return {
      setArticleTime,
      setTransferDtm,
      // setApproveDtm,
      setStyle,
      setReporterName,
      setIsCopyArticle,
    };
  },
});
</script>
